<script>
import { onMounted } from 'vue'
import useAuth from '@/composables/useAuth'
import { useRouter } from '@/utils/index'

export default {
  setup() {
    const { router } = useRouter()
    const { logout } = useAuth()

    onMounted(async () => {
      try {
        await logout()
        router.push({ name: 'login' })
      } catch (error) {
        console.error(error)
      }
    })

    return () => ''
  },
}
</script>
